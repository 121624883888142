import React, { FC, useEffect, useState } from "react";
import { IconType } from "../model/icon/IconType";
import { bem } from "../util/bem";
import { Icon } from "./Icon";

import "./NavigationView.scss";

interface NavLinkProps {
  align?: "right";
  to: string;
  classModifier?: string; // FIXME: THIS IS A REALLY HACKY WAY TO KEEP THE LOGO ON MOBILE
}

function NavLink(props: React.PropsWithChildren<NavLinkProps>) {
  const content = props.children ? props.children : null;

  return (
    <li className={bem("NavLink")}>
      <a className={bem("NavLink", "item", props.classModifier)} href={props.to}>
          {content}
      </a>
    </li>
  )
}

interface NavMenuButtonProps {
  open: boolean;
  menuCallback: () => void;
}

const NavMenuButton: FC<NavMenuButtonProps> = (props) => {

  return (
    <Icon 
      type={props.open ? IconType.Cross : IconType.Menu} 
      onClick={props.menuCallback}
      className={bem("Nav", "menuButton")} 
    />
  );
} 

export function Nav() {
  const MOBILE_TRIGGER_WIDTH = 830;

  const [mobile, setMobile] = useState<boolean>(window.innerWidth <= MOBILE_TRIGGER_WIDTH ? true : false);
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    const useMobileMode = () => {
      if (window.innerWidth <= MOBILE_TRIGGER_WIDTH) {
        setMobile(true);
      } else {
        setMobile(false);
      }
    };

    window.addEventListener("resize", useMobileMode);


    return () => {
      window.removeEventListener("resize", useMobileMode);
    }
  }, [setMobile])

  const menuJSX = mobile
    ? open
      ? [
        <NavMenuButton open={true} menuCallback={setOpen.bind(undefined, false)} />,
        (
          <ul className={bem("Nav", "container", "mobile")}>
            <NavLink classModifier="mobile" to="/">Home</NavLink>
            <NavLink classModifier="mobile" to="/about">About</NavLink>
            <NavLink classModifier="mobile" to="/gallery">Gallery</NavLink>
            <NavLink classModifier="mobile" to="/products">Products</NavLink>
            <NavLink classModifier="mobile" to="/white-papers">White Papers</NavLink>
            <NavLink classModifier="mobile" to="/contact">Contact Us</NavLink>
          </ul>
        )
      ]

      : (
        <NavMenuButton open={false} menuCallback={setOpen.bind(undefined, true)} />
      )
    : (
      <ul className={bem("Nav", "container")}>
        <NavLink to="/">Home</NavLink>
        <NavLink to="/about">About</NavLink>
        <NavLink to="/gallery">Gallery</NavLink>
        <NavLink to="/products">Products</NavLink>
        <NavLink to="/white-papers">White Papers</NavLink>
        <NavLink to="/contact">Contact Us</NavLink>
      </ul>
    );

  return (
    <nav className={bem("Nav")}>
      <NavLink classModifier="logo" to="/">
        {/* height and width to avoid layout movement on logo pop-in */}
        <img className={bem("NavLink", "item", "logo")} src="/res/img/logo/logo.svg" alt="" height={"45"} width={"95"} />
        <span className={bem("NavLink", "item", "name")}>CUVOS</span>
      </NavLink>

      {menuJSX}
    </nav>
  )
}