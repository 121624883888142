import { FC } from "react";
import { YouTubeEmbed } from "../components/YouTubeEmbed";
import { bem } from "../util/bem";

import "./GalleryPage.scss";
import { Carousel } from "../components/Carousel";

const galleryPageBEM = bem.bind(this, "GalleryPage");

type ExampleProps = {
  name?: string;
  raw?: string;
  enhanced?: string;
  embedId?: string;
}

export const GalleryPage: FC<{}> = () => {
  const videoIds = [
    "-KvgSGEp7gs",
    "VQTT47NlV2I",
    "IbYFAaoE9qI",
    "OFTqrepLOOI",
    "Z55Q_qAWB1k",
    "6SVLYM81iOQ",
    "X3NHnqBPrSs"
  ]

  return (
    <div className={galleryPageBEM()}>
      <Carousel videoIds={videoIds} />
    </div>
  );
}