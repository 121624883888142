import { FC } from "react";
import { bem } from "../util/bem";

import "./ProductPage.scss";
import "./HawkeyeProductPage.scss";
import { SpecItem } from "./ProductPage";
import { NavLink } from "react-router-dom";

const $bem = bem.bind(undefined, "ProductPage");

export const HawkeyeProductPage: FC = () => {
  return (
    <div className={$bem()}>
      <div className={$bem("section")}>
        <h2 className={$bem("heading", "product")}>Hawkeye</h2>
        <div className={$bem("sectionBody")}>
          <p className={$bem("paragraph")}>
            The Cuvos Hawkeye is the most sophisticated biologically-inspired
            camera ever developed. Hawkeye uses Cuvos' Neuromorphic Enhanced
            Sensor Technology (NEST), which combines image enhancement with 
            spatio-temporal change, edge-detection filtering, and event 
            generation. This results in the most comprehensive camera technology
            available, providing: raw frames, enhanced frames, change/edge
            detection views, and event-based sensor data. Hawkeye is perfect
            for machine vision applications: from high-performance compute
            networks to low-power, sparse data processing at the edge.
          </p>
          <p className={$bem("paragraph")}>
            Hagle Eye can be fitted with high density colour image sensors, as
            well as infra-red (IR), ultra-violet (UV), low-light, or other
            similar image sensors to facilitate a comprehensive input
            spectrum. We can customise Hawkeye to be compatibe with your favourite
            image sensor.
          </p>

          <p className={$bem("paragraph")}>
            <NavLink to={"/contact"}>Contact us</NavLink> for more information
            or a demonstration.
          </p>
        </div>

        <div className={$bem("sectionBody")}>
          <h3 className={$bem("heading", "spec")}>
            System Specifications (R&D Model)
          </h3>
          <div>
            <SpecItem name="Size" description="25cm × 20cm × 7.5cm" />
            <SpecItem name="Weight" description="<1 kg" />
            <SpecItem name="Power Consumption" description="<10 W (R&D Model)" />
            <SpecItem name="Output" description="raw video, enhanced video, edge/change detection views, events" />
          </div>
          <h3 className={$bem("heading", "spec")}>
            Sensor Specifications<sup>*</sup>
          </h3>
          <div>
            <SpecItem name="Sensor Size" description="2/3 inch equivalent (8.8mm x 7.0mm)" />
            <SpecItem name="Max Framerate" description="120 fps" />
            <SpecItem name="Sensor Dynamic Range" description="68.6 dB" />
            <SpecItem name="Number of effective pixels" description="2592h × 2056v" />
            <SpecItem name="Sensitivity" description="30,000 e/lux/sec (Green)" />
            <SpecItem name="Data Rate" description="up to 864 Mbps (raw video/enhanced video)" />
            <SpecItem name="Spectral Band" description="400 – 700nm RGB, 800 – 1000nm NIR" />
          </div>
          <div className={$bem("footnoteContainer")}>
            <div className={$bem("footnote")}>
              <sup>*</sup>: Sensor specifications are derived from the specific 
              sensor used. Values quoted are for the Canon 3U5MGXBAI.
            </div>       
          </div>
        </div>
      </div>
    </div>
  );
}