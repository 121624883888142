import { FC } from "react";
import { bem } from "../util/bem";

import "./WhitePaperPage.scss"

const em = bem.bind(this, "WhitePaperPage");

export const WhitePaperPage: FC<{}> = () => {
  return (
    <div className={em()}>
      <iframe title="pdfContainer" className={em("pdfContainer")} src="/white-papers/vision.pdf">
      </iframe>
    </div>
  );
}

export default WhitePaperPage;